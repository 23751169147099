import { render, staticRenderFns } from "./Friend.vue?vue&type=template&id=3052b789"
import script from "./Friend.vue?vue&type=script&lang=js"
export * from "./Friend.vue?vue&type=script&lang=js"
import style0 from "./Friend.vue?vue&type=style&index=0&id=3052b789&prod&lang=scss"


/* normalize component */
import normalizer from "!../../.yarn/__virtual__/vue-loader-virtual-1bb53978ad/5/.yarn/berry/cache/vue-loader-npm-15.11.1-829412283a-10c0.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports